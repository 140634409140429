@import "_fontfaces";

body{
	font-size:13px;
	font-family: 'latoregular';
	color:#1B1B1B;
}

#app{
	overflow:hidden;
}

.logo{
	margin-bottom:0;
}

// FONTS USADAS
.lato-blk{
	font-family: 'latoblack';
}

.lato-bld{
	font-family: 'lato-bold';
}

.lato-bld-itc{
	font-family: 'latobold_italic';
}

.lato-reg{
	font-family: 'latoregular';
}

.lato-med{
	font-family: 'latomedium';
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

@include text-emphasis-variant('.text-theme',$theme);

.logo{
	margin-bottom:0;
}

.stars-ratting{
	display: inline-block;
	width: 178px;
	height: 40px;
	position: relative;
	background-color:#E7E7E7;

	&:before,
	&:after{
		content: '';
		display: block;
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
	}

	&:before{
		width: 100%;
		background: url(../images/pattern-stars.png) center center;
		z-index: 2;
	}

	&:after{
		background-color: #4D4D4D;
		z-index: 1;
		transition: width 0.3s linear;
	}

	$n:0;

	@for $i from 0 through 10{
		$n: $n + 0.5;

		$w: $n * 20%;

		&[data-nota="#{$n}"]:after{
			width: $w;
		}
	}
}

.owl-carousel{

	.owl-item{

		img{
			display:inline-block !important;
			width:auto !important;
		}

	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

.alert{
    border-radius:0;
    @extend .animated;
    @extend .fadeIn;

    &.alert-success,
    &.alerta-success{
        background: map-get($cores,'green-darken-2');
        color:#FFF;
    }

    &.alert-danger,
    &.alerta-danger{
        background: map-get($cores,'red-darken-2');
        color:#FFF;
    }

    &.alert-info,
    &.alerta-info{
        background: map-get($cores,'blue-darken-2');
        color:#FFF;
    }

    &.alert-warning,
    &.alerta-warning{
        background: map-get($cores,'yellow-darken-2');
        color:#FFF;
    }
}

.alertas{
	position: fixed;
    z-index:10000;
	bottom:0;
	right:0;
	padding:15px;
	max-width:100%;
	min-width:250px;

	.alert{
		box-shadow:0 0 8px rgba(#000,0.8);
	}
}

lazy-image{
	background-color:transparent;
}

.fnt-13{
	font-size:13px;
}

.fnt-14{
	font-size:14px;
}

.title-radios{
	font-size:18px;
}

.radios{
	margin-bottom:20px;
}

.slick-slide img{
	display:inline-block !important;
}

@keyframes anima-onda{

	0%{
		background-position:0;
	}

	100%{
		background-position:10vw;
	}

}

.progress-bar-custom{
	transition:all 0.6s linear;
	animation:anima-onda 30s infinite alternate;
}
