.card-produto{
	max-width:285px;
	margin-bottom:35px;
	margin-left:auto;
	margin-right:auto;
	text-align:center;

	@include media-breakpoint-up(md){
		text-align:left;
	}

	.img-figure{
		position:relative;
		padding-top:10px;
		padding-bottom:10px;
		transition:box-shadow 0.6s linear;

		.box-controls{
			display:flex;
			align-items:center;
			position:absolute;
			top:0;
			left:0;
			flex-direction:column;
			padding-top:10px;
			padding-bottom:10px;

			& > *{
				display:inline-block;
				font-size:20px;
				width:(55 / 20) * 1em;
				height:(55 / 20) * 1em;
				line-height:(55 / 20) * 1em;
				text-align:center;
				padding:0;
				border-radius:100%;
				margin-bottom:8px;
				margin-bottom:6px;

			}

		}

	}

	.card-content{

		.title{
			font-size:15px;
			color:#1B1B1B;
			margin-bottom:2px;
		}

		.title,
		.preco-atual{
			font-family: 'latoblack';
		}

		.preco-atual{
			color:$theme2;
			font-size:25px;
			line-height:1;
			margin-bottom:5px;
		}

		.desc{
			font-style:italic;
		}

	}

	&:hover{

		.img-figure{
			box-shadow:0 0 8px rgba(#000,0.3);
		}

	}

}