.media-incrivel{
	position:relative;

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		align-items:center;
	}

	.box-img{
		width:(57 / 21) * 1em;
		height:(57 / 21) * 1em;
		line-height:(57 / 21) * 1em;
		font-size:21px;
		text-align:center;
		color:#CCC;
		border:1px solid #CCC;
		border-radius:100%;
		transition:border-color 0.6s,color 0.6s linear;

		@include media-breakpoint-up(lg){
			margin-right:8px;
		}

	}

	.box-text{
		font-style:italic;
		color:#282828;

		a{
			font-style:normal;
			font-family: 'latoblack';
			color:$theme;
		}

	}

	&:hover{	

		.box-img{
			border-color:#000;
			color:#000;
		}

	}

	& + .media-incrivel{
		margin-left:10px;
	}

	&.media-incrivel-logada{

		&:hover{

			@include media-breakpoint-up(xl){
				.menu-logado-topo{
					display:block !important;
				}
			}

		}

	}

}