.form-pesquisas-topo{
	width:100%;
	max-width:311px;

	@include media-breakpoint-down(lg){
		margin-bottom:20px;
		padding-left:15px;
		padding-right:15px;
	}

	form{

		.input-group,
		.form-group{

			.form-control{
				@include place(#1E1E1E);
				background-color:#EEE;
				border-radius:30px 0 0 30px;
				height:auto;
				min-height:45px;
				font-size:14px;
				border:none;
				padding-left:25px;
				padding-right:20px;
				@include sombra(#EEE);

				@include media-breakpoint-up(xl){
					min-height:65px;
				}

			}

			.btn{
				border-radius:0 30px 30px 0;
			}

		}

	}

}