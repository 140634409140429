.rodape{
	box-shadow:0 0 8px rgba(#000,0.3);
	padding-top:20px;

	.btn-whats{
		z-index:500;
		position:fixed;
		bottom: 20px;
		right:(15 / 30) * 1em;
		background-color:#4caf50;
		font-size:40px;
		color:#FFF;
		border-radius:100%;
		display:flex;
		align-items:center;
		justify-content:center;
		flex-wrap:wrap;
		width: 65px;
		height: 65px;
		transition:box-shadow 0.6s, color 0.6s linear;
		box-shadow:0 0 16px rgba(0,0,0,0.5);

		&:hover{
			box-shadow:0 0 24px rgba(0,0,0,0.5);
			color:#FFF;
		}

	}

	a{

		&:hover{
			color:$theme;
		}

	}

	.numero-tel{
		font-size:18px;
	}

	.rodape-principal{

		& > *{
			border-bottom:1px solid #e8e8e8;
		}

		.rodape-texto{
			padding-bottom:2px;

			.row{

				& > *{
					margin-bottom:8px;
				}

			}

		}

	}

	.rodape-menu{
		padding-top:20px;

		.title-rodape{
			font-size:16px;
			font-family: 'lato-bold';
			margin-bottom:25px;
		}

		.menu-rodape{
			list-style-type:none;
			margin-bottom:0;
			padding-left:0;

			li{
				margin-bottom:6px;

				&.active > a,
				&:hover  > a{
					color:$theme;
				}

			}

		}

	}

	.rodape-footer{
		padding-top:25px;
		background-color:$theme2;
		color:#FFF;
		padding-bottom:15px;
		font-size:10px;

		.gv8{
			max-width:44px;
		}

	}

}
