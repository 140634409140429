.pedido{
	display: flex;
	background: #eeeeee;
	font-size: 12px;
	margin-bottom: 10px;

	.btn-medium{
		min-width:inherit;
	}

	&.pedido-realizado{

		.pedido-header{
			background-color: #000;
		}

	}

	&.pedido-aprovado{

		.pedido-header{
			background-color: #000;
		}

	}

	&.pedido-transporte{

		.pedido-header{
			background-color: #000;
		}

	}

	&.pedido-entregue{

		.pedido-header{
			background-color: #000;
		}

	}

	.pedido-header,
	.pedido-col .btn{
		font-family: 'latoblack';
	}

	.pedido-header{
		background-color:darken(#eee, 20%);
		color: #FFF;
		padding: 10px;
		background-color:#000000;
		font-size:12px;

		.num{
			margin-bottom: 10px;

			.maior{
				font-size:15px;
			}

		}

		@include media-breakpoint-up(xl){
			width: 25%;
		}
	}

	.pedido-body{
		display:flex;
		flex-grow:1;
		align-items: center;
		font-size:14px;
		background-color:#EDEDED;
		font-family: 'latomedium';

		@include media-breakpoint-down(sm){
			flex-wrap: wrap;

			.pedido-col{
				width: 100%;
			}
		}
	}

	.pedido-col{
		padding: 5px 15px 5px 35px;
		min-height:50px;
		color:#444;
		text-align:center;

		.title{
			margin-bottom: 5px;
		}

		.title,
		.content{
			line-height:1;
		}

		@include media-breakpoint-up(md){
			width: 33.333%;

			&:last-child{
				text-align:center;

			}

		}
	}
	
	@include media-breakpoint-down(lg){
		flex-direction:column;

		.pedido-body{
			padding: 15px;
		}
	}
}