.forma-pagamento{
	color:#333333;
	font-size:13px;
	
	&:first-child{
		padding-top:30px;
	}

	.title-mais-small{
		font-size:13px;
	}

	.title-small{
		font-size:16px;
		font-family: 'lato-bold';
		margin-bottom:0;
	}

}