.form-newsletter{

	form{
		max-width:560px;
		margin-left:auto;
		margin-right:auto;

		.form-control{
			background-color:transparent;
			border:none;
			border-bottom:4px solid #FFF;
			border-radius:5px;
			font-style:italic;
			@include place(#FFF);
			box-shadow:none;
			padding-bottom:18px;
			height:auto;
			padding-left:0;
		}

		.btn{
			color:#FFF;
			padding:0;
			border:none;
			border-bottom:4px solid #FFF;
			padding-right:8px;
			font-size:16px;

			@include hover-focus{
				box-shadow:none;
			}

		}

	}

}