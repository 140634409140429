.menu-collapse{
	background-color:$theme;
	color:#FFF;
	list-style-type:none;
	padding-left:0;
	margin-bottom:0;
	z-index:1000;

	@include media-breakpoint-down(lg){
		width:100%;
		border-top:1px solid #FFF;
		border-bottom:1px solid #FFF;
	}

	@include media-breakpoint-up(xl){
		position:fixed;
		display:block !important;
		text-align:left;
		top:0;
		border-right:1px solid #FFF;
		left:-200px;
		width:200px;
		transition:all 0.6s linear;
		height:100% !important;
		top:0;

	}

	.fa,
	.fas{
		transition:all 0.6s linear;
	}

	.fa-minus{
		transform:rotate(180deg);
	}

	&,
	ul,
	ol{
		padding-left:0;
		list-style-type:none;
		margin-bottom:0;
	}

	ul{
		width:100%;
		border-top:1px solid #FFF;
	}

	a{
		display:block;
		padding:10px 15px;
		flex-grow:1;
	}

	.item{
		display:flex;
		flex-wrap:wrap;

		a{
			width:70%;
		}

		a:hover,
		.subcategoria-box:hover{
			background-color:darken($theme,15%);
		}

		.subcategoria-box{
			width:30%;
			display:flex;
			flex-wrap:wrap;
			justify-content:center;
			align-items:center;
			transition:all 0.6s linear;

			.btn{
				background-color:transparent;
				color:#FFF;
				height:100%;
				font-size:22px;

				&:focus{
					box-shadow:none;
				}

			}

		}

	}

	li:hover > .item > a,
	li.active > .item > a,
	li:hover > .item > .subcategoria-box{
		color:#FFF;
		border-color:#FFF;
	}

	li + li > .item{
		border-top:1px solid #FFF;
	}

}