.tab-custom{

	.nav-item{

		.nav-link{

			&:first-child{
				padding-left:0;
			}

		}

	}

}