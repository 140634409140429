.tabs-produto{
	margin-bottom:30px;
	font-size:14px;
	color:#040303;

	.tab-title{
		font-size:18px;
		font-family: 'lato-bold';
		margin-bottom:15px;
	}

	.nav-tab-prod{
		margin-bottom:0;
		display: flex;
		flex-wrap:wrap;
		list-style:none;
		font-family: 'lato-bold';
		padding-left:0;

		a{
			display: block;
			padding:5px 20px;
			background: #000;
			color:#FFF;
			font-size:14px;
			min-width:122px;

			&:hover{
				background: darken($theme,10%);
				text-decoration: none;
			}
		}

		.active a,
		.active a:hover{
			background: #EDEDED;
			color:#000;
			cursor: default;
		}
	}


	.tab-produto{
		background-color:#EDEDED;
		padding: 15px 25px;
		display: none;

		&.active {
			display: block;
			animation: fadeIn 0.3s linear;
		}
	}
}