.produto-table-separado{
	margin-bottom:23px;

	& > *{
		background-color:#EDEDED;
		padding:20px 15px;
		flex-grow:1;

		.texto{
			font-size:16px;
		}

	}

	.produto-head{

		@include media-breakpoint-up(lg){
			margin-right:10px;
			max-width:255px;
		}

	}

	.produto-body{
		
		@include media-breakpoint-up(lg){
			display:flex;
			max-width:440px;
			flex-wrap:wrap;
			justify-content:space-between;
		}

		.produto-body-boxes{
			flex-grow:1;

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				max-width:295px;
				margin-right:auto;
				margin-left:auto;
				justify-content:space-between;
			}

		}

	}

	.produto-footer{
		background-color:#000;
		color:#FFF;
		font-weight:bold;
	}

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}

}