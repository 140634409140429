.botao-categorias{
	position:relative;
	text-align:left;
	align-items:center;

	& > .btn{
		font-family: lato-bold;
		@include hover-focus{
			color:$theme2;

			.icon-arrow-down{

				@extend .icon-arrow-down-hover;

			}

		}

		@include mb(){
			width:100%;
			padding-top:10px !important;
		}

	}

	ul,
	ol,
	& > .btn{
		list-style-type:none;
		padding-left:0;
		color:#FFF;
		margin-bottom:0;
		box-shadow:none;

		button,
		.btn{
			box-shadow:none;
		}

	}

	@include dk(){
		min-width:200px;
	}

	.media-incrivel{
		align-items:center;

		.media-icon{
			margin-right: 15px;
		}

		.media-text{
			text-align:left;
			line-height:1;

			@include mb(){
				padding-top:5px;
				text-align:center;
				margin-bottom:10px;
				border-bottom:1px solid darken($theme,10%);
				padding-bottom:6px;
			}

			.icon-arrow-down{
				vertical-align:middle;
				margin-left:14px;
			}

		}

	}

	.menu-categorias-side{
		color:#FFF;
		border-right:1px solid darken($theme,10%);
		transition:left 0.6s linear;
		background-color:$theme;

		@include dk(){
			overflow:auto;
			z-index:1000;
			display:block !important;
			position:fixed;
			top:0;
			left:-250px;
			width:250px;
			height:100% !important;
		}

		.fas{
			transition: transform 0.6s linear;

			&.fa-minus{
				transform:rotate(180deg);
			}

		}

		&,
		ol,
		ul{
			list-style-type:none;
			padding-left:0;
		}

		ul,
		ol{
			width:100%;
		}

		.item{
			display:flex;
			flex-wrap:wrap;

			& > a,
			& > .box-button{
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

			& > a{
				width:82%;
				flex-grow:1;
				padding:10px 15px;
				background-color:$theme;

				@include hover-focus{
					background-color:darken($theme,6%);
				}

			}

			& > .box-button{
				width:18%;
				transition:border-color 0.6s linear;
				display:flex;
				flex-wrap:wrap;

				.btn{
					width:100%;
					border-radius:0;
					font-size:18px;
					transition:all 0.6s linear;

					@include hover-focus{
						box-shadow:none;
					}

				}

			}

		}

		.item:hover > a,
		.item:hover > .box-button{
			border-color:#FFF;
		}

	}

}

body.menu-categorias-active{

	.menu-categorias-side{
		left:0;
	}

}

body.sombra{

	.bg-menu{
		background-color:rgba(#000,0.5);
		height:100%;
		opacity:1;
	}

}
