.menu-escolha-topo{
	position:relative;

	& > .btn{
		padding:0;
		border:none;
		font-size:20px;
		box-shadow:none;
		line-height:1;
		border-radius:0;

		@include media-breakpoint-up(md){
			display:none !important;
		}

	}

	.menu-bar-topo{
		list-style-type:none;
		margin-bottom:0;
		padding:0;

		@include media-breakpoint-up(md){
			display:flex;
			flex-wrap:wrap;
		}

		@include media-breakpoint-down(sm){
			position:absolute;
			top:100%;
			right:0;
			z-index:700;
			min-width:170px;
			text-align:right;
			box-shadow:0 0 8px rgba(#000,0.5);
			background-color:darken($theme,10%);
		}

		& > li{

			@include media-breakpoint-up(md){
				@include margin(0,7);
			}

			&:hover >  a,
			&.active > a{
				color:$theme2;
			}

			& > a{
				display:block;

				@include media-breakpoint-up(md){
					position:relative;

					&:before{
						content:'';
						display:block;
						position:absolute;
						left:calc(100% + 7px);
						top:calc(50% - 2px);
						width:1px;
						height:100%;
						max-height:10px;
						background-color:#FFF;
						transform:translateY(-50%);
					}

				}
				
				@include media-breakpoint-down(sm){
					padding:10px 15px;
				}

			}

			&:last-child{

				& > a{

					&:before{
						display:none;
					}

				}

			}

		}

		&.show{

			@include media-breakpoint-down(sm){
				display:block !important;
				max-height:calc(100vw - 100px);
				min-height:100px;
				overflow:auto;
			}

		}

	}

}