.menu-categorias-principais{
	list-style-type:none;
	padding-left:0;
	margin-bottom:0;
	color:#FFF;

	@include dk(){
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		align-items:center;
		flex-grow:1;
	}

	@include mb() {
		a{
			padding: 10px;
		}
		.menu-item{
			.item{
				display: flex;
			}
		}
	}

	& > li{

		&.menu-diferente{
			text-transform:uppercase;

			a{
				padding: 10px;
			}

			&:hover .item > a{
				color:#000;

			}

		}

		@include dk(){
			margin-right:20px;
		}

		&:last-child{

			@include dk(){
				margin-right:0;
			}

		}

		&:hover  > .item{

			.menu-dropdown{

				@include dk(){
					display:block !important;
					overflow:auto;
					max-height:calc(100vh - 270px);
				}

			}

		}

	}

	li{

		&:hover{

			.icon-arrow-down{

				@extend .icon-arrow-down-hover;

			}

		}

		.item{


			@include dk(){

				&:before{
					content:'';
					position:absolute;
					top:0;
					left:-16px;
					width:1px;
					height:100%;
					background-color:#FFF;
				}

			}

		}

		& > .item{
			position:relative;

			& > a{
				font-size:16px;
				font-family: 'lato-bold';
			}

			.btn{
				box-shadow:none;

			}

			.menu-dropdown{
				padding-left:0;
				margin-bottom:0;
				list-style-type:none;
				background-color:darken($theme,10%);
				color:#FFF;
				width:100%;

				& > li{

					& > a{
						display:block;
						padding:10px 15px;
					}


					& + li{

						& > a{
							background-color:darken($theme,10%);
							border-top:1px solid darken($theme,15%);
							border-bottom:1px solid darken($theme,15%);
						}

					}

					&.active > a,
					&:hover  > a{
						background-color:darken($theme,17%);
						border-color:darken($theme,20%);
					}

				}

				@include dk(){
					position:absolute;
					top:100%;
					left:0;
					z-index:600;
					min-width:180px;
				}

			}

		}

		.btn{
			border:none;

			&[aria-expanded="true"]{

				.icon-arrow-down{

					@extend .icon-arrow-down-hover ;

				}

			}

		}

		&.active > .item,
		&:hover  > .item{

			& > a{
				@include dk(){
					color:#000;
				}
			}

		}

	}

}
