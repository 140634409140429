.breadcrumb-internas{
	background-color:#010101;
	position:relative;
	justify-content:flex-end;
	color:#FFF;
	font-size:12px;
	border-radius:0;
	font-weight:bold;
	margin-bottom:45px;

	.breadcrumb-item{

		&.active{
			color:inherit;
		}

		& + .breadcrumb-item{
			padding-left:2px;

			&:before{
				display:none;
			}

		}

	}

	@include beforeAfter{
		content:'';
		display:block;
		width:100vw;
		height:100%;
		position:absolute;
		top:0;
		background-color:inherit;
	}

	&:before{
		left:100%;
	}

	&:after{
		right:100%;
	}
	

}