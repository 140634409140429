.segura-carousel{
	position:relative;

	.controls-carousel{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		justify-content:center;
		align-items:flex-end;
		padding-bottom:40px;

		@include media-breakpoint-down(sm){
			position:relative;
			background-color:$theme;
			padding-bottom:5px;
			padding-top:5px;
		}

		.clearfix{

			@include media-breakpoint-up(lg){
				@include make-container();
				@include make-container-max-widths();
			}

			& > *{
				z-index:100;
				position:relative;
				font-size:18px;
				width:(55 / 18) * 1em;
				height:(55 / 18) * 1em;
				line-height:(55 / 18) * 1em;
				background-color:rgba(#FFF,0.2);
				color:#FFF;
				display:inline-block;
				text-align:center;
				border-radius:100%;
				@include margin(0,3);
				font-weight:bold;

				@include hover-focus{
					background-color:#e9e5e2;
					color:#000;
				}

			}

		}

	}

}

.section-vantagens{
	padding-top:30px;
	padding-bottom:10px;

	figure{
		margin-bottom:20px;
	}

}

.progress-bar-custom{
	width:100%;
	background-repeat:repeat-x;
	height:8px;
}

.section-produtos{
	padding-bottom:30px;
}

.section-galeria{
	padding-bottom:25px;
}

.section-full-carousel{
	padding-bottom:40px;

	.slick-slider{
		z-index:200;
	}

	.segura-carousel{

		.controls-carousel-diferente{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			font-size:20px;
			display:flex;
			justify-content:flex-end;
			align-items:flex-end;
			padding-bottom:35px;

			@include media-breakpoint-down(md){
				position:relative;
				padding-top:35px;
				justify-content:center;
				font-size:40px;
			}

			.clearfix{

				& > *{
					@include margin(0,4);
					z-index:400;
					position:relative;

					@include hover-focus{
						color:$theme2;
					}

				}

			}

		}

	}

}

.section-newsletter{
	padding-bottom:40px;
	text-align:center;

	@include media-breakpoint-up(lg){
		text-align:left;
	}
	
	.bg-newsletter{
		padding-top:60px;
		padding-bottom:30px;
		background-repeat:no-repeat;
		background-size:cover;
		background-position:center center;

		@include media-breakpoint-down(lg){
			padding-left:20px;
			padding-right:20px;
		}

		.row{

			& > *{
				margin-bottom:30px;

				@include media-breakpoint-down(lg){
					padding-left:20px;
					padding-right:20px;
				}
				
			}

		}

		.title{
			color:#FFF;
			font-size:23px;
			word-break:break-all;

			@include media-breakpoint-up(md){
				padding-left:20px;
			}

			span{
				position:relative;

				&:before{
					content:'';
					display:block;
					position:absolute;
					right:calc(100% + 10px);
					top:50%;
					width:10px;
					height:58px;
					background-color:#FFF;
					transform:translateY(-50%);
				}

			}

		}

	}

}

.section-promocao{
	padding-bottom:105px;
}