.paginacao-nova{

	& > *{
		@include margin(0,6);
	}

	.page-item{

		.page-link{
			transition:all 0.6s linear;
			padding:0;
			min-width:24px;
			line-height:24px;
			min-height:24px;
			text-align:center;
			font-size:14px;
			font-weight:bold;
			border-radius:100%;
			@include button-variant($theme,$theme);
		}

	}

}