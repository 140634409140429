.radio-custom{

	.nav-link{

		&.active{
			background-color:transparent;
		}

	}

	& > label{
		display:inline-block !important;
		position:relative !important;
		width:100% !important;
		height:100% !important;
	}

	span{
		position:relative;
		display:inline-block;
		width:1.7em;
		height:1.7em;
		border-radius:100%;
		border:5px solid transparent;
		vertical-align:sub;
		background-color:transparent;
		margin-right:0.6em;
		transition:all 0.6s linear;
		font-size:12px;

		&:before{
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			border-radius:100%;
			transform:scale(1);
			background-color:transparent;
			border:2px solid transparent;
			transition:all 0.6s linear;
		}

	}

	&.radio-custom-grande{
		
		.circle-legal{
			font-size:18px;
			vertical-align:bottom;
		}

	}

	input{
		position:fixed;
		left:-200vw;

		&:checked ~ label > .circle-legal:before{
			transform:scale(1);
		}

	}

	&.radio-bolinha-centro{

		span{
			border:2px solid #1F1F1F;

			&:before{
				border:3px solid #FFF;
			}

		}

		input:checked ~ label > .circle-legal{

			&:before{
				background-color:#1f1f1f;
				border-color:#FFF;
			}

		}

	}

	&.radio-custom-padrao{

		.circle-legal{
			background-color:#000000;
		}

		input{

			&:checked ~ label > .circle-legal{
				background-color:$theme;

				&:before{
					background-color:$theme;
				}

			}

		}

	}

	&.radio-custom-categorias{

		span{
			font-size:11px;
			background-color:#000;

			&:before{
				border-color:#000;
				background-color:#000;
			}

		}

		label{
			color:#1f1f1f;
		}

		input{

			&:checked ~ label{

				.circle-legal{
					background-color:#FFF;
					border:2px solid #000;

					&:before{
						border-color:#FFF;
						border-width:3px;
					}

				}

			}

		}

	}

}

.nav-link.active{
	color:#000 !important;

	input{

		& ~ label > .circle-legal{
			background-color:#1f1f1f;

			&:before{
				transform:scale(1);
			}

		}

	}

}