.depoimento{
	padding-top:15px;
	border-top:1px solid #E7E7E7;
	padding-bottom:15px;
	font-size:12px;

	@include media-breakpoint-up(lg){
		padding-left:22px;
	}

	.rating{
		margin-bottom:20px;
		font-size:18px;
	}

}