@import "_home.scss";

.internas{
	padding-bottom:60px;

	.title-internas{
		font-size:27px;
		color:#010101;
		font-family: 'latoblack';
		margin-bottom:45px;
		word-break:break-all;
	}

	.hr-divider{
		border-top:8px solid #EDEDED;
	}

	&.internas-cadastro{

		form{

			.fieldset-cadastro{

				& > .limitador{
					padding-left:0 !important;

					& > .row{

						@include media-breakpoint-up(lg){
							padding-left:20px;
						}

					}

				}

			}

		}

	}

	&.internas-meus-pedidos-detalhes{

		.bg-gray{
			background-color:#EDEDED;
			padding:15px;
			font-size:16px;
		}

		.row-pedidos{

			@include media-breakpoint-down(md){
				padding-left:20px;
				padding-right:20px;
			}

		}

		.pedido-realizado{
			min-width:160px;
			flex-grow:1;

			.tab-custom{
				background-color:#EDEDED;
				padding:8px 16px 8px 32px;
				position:relative;
				margin-bottom:20px;
				z-index:2;
				text-align:center;
				color:#1D1D1D;

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					border:18px solid transparent;
					z-index:-2;
					height:100%;
				}

				&:before{
					left:100%;
					top:0;
				}

				&:after{
					border-left-color:#FFF;
					left:0;
					top:0;
				}

				&.active{
					background-color:darken($theme,0%);

					&:before{
						left:calc(100% + 1px);
						border-left-color:darken($theme,0%) !important;
					}

				}

			}

			& + .pedido-realizado{

				.tab-custom{

					&:before{
						border-left-color:#EDEDED;
					}

				}

			}

			&:first-child{

				.tab-custom{

					&:after{
						border-left-color:transparent;
					}

				}

			}

			&:last-child{

				.tab-custom{

					&:before{
						border-left-color:transparent;
					}

				}

			}

		}

	}

	&.internas-categorias-detalhes{

		.card.card-add-carrinho{
			padding:18px 12px;
		}

		lazy-image{
			display:inline-block;
		}

		.internas-content{
			padding-top:35px;
		}

		.fotos-detail-prod{

			.h5{
				color:#000000;
				font-size:12px;
				margin-bottom:22px;
			}

		}

		.title-valor{
			font-size:35px;
			line-height:1;
			margin-bottom:0;

			.text-small{
				font-size:15px;
			}

		}

		.texto-parcela{
			font-size:15px;
		}

		.rating{

			.fa{
				margin:0 2px;

				&:first-child{
					margin-left:0;
				}

				&:last-child{
					margin-right:0;
				}

			}

		}

		.divider-hr{
			border-top:5px solid #D6D6D6;
		}

		.row-imagens{

			& > *{
				@include margin(0,15);
				border:2px solid transparent;
				transition:border-color 0.6s linear;

				@include hover-focus(){
					border-color:$theme;
				}

			}

		}

		.media-legal{
			font-size: 40px;
			margin-bottom:35px;
			text-align:center;
			align-items:center;

			.nota{
				color: #656565;
			}

			.rating,
			.nota{
				line-height:1;
			}

		}

		.details-prod-text{
			font-size: 16px;
			color: #383838;

			.card-body-cep{
				color:#333333;
				font-size:12px;

				.form-control{
					@include place(#8F8F8A);
					font-size:13px;
				}

				.form-control,
				.input-group-addon .btn{
					border-color:#ccc;
				}

				.fa-truck{
					margin-right:5px;
					font-size:15px;
				}

				.cep-text{
					font-size:14px;
					color:#333333;
				}

			}

			.avaliacoes{
				font-size:10px;
				align-self:center;
				color:#263351;
				font-family: 'latoblack';
			}

			.card{

				.btn-theme.btn-expanded{
					font-size:18px;

					.fa{
						font-size:25px;
						margin-right:3px;
					}

				}

			}

			.nome,
			.cod{
				color: #000;
			}

			.nome{
				font-size: 18px;
				color:#000;
				margin-bottom: 15px;
			}

			.cod{
				margin-bottom: 15px;
				font-size:13px;
			}

			.rating{
				color:#e6e6e6;
				font-size:13px;

				.full{
					color: #4E4E4E;
				}

				.half{
					position: relative;

					&:after{
						content: "\f089";
						color: #4E4E4E;
						position:absolute;
						left:0;
					}
				}
			}

			.bg-grey{
				background: #ececec;
			}

			.card{
				border-radius:0;
				border:none;

				.btn-redondo{

					.fa{
						margin-right:10px;
						font-size:26px;
					}

				}

				.card-body{

					& > .row{
						align-items:center;
						margin-bottom:10px;
					}

				}

				.form-control{
					border-color:#cecece;
					min-height:44px;
					border-right:none;
					font-size:12px;
					color:#1F1F1F;
					border-radius:0;

					@include placeholdIt{
						color:#1F1F1F;
					}

				}

				.input-group-addon{

					.btn{
						border-color:#cecece !important;
						border-left:none;
						font-size:13px;
						color:#1F1F1F;
					}

				}

				.btn-frete{
					color:#FFF;
					width:28px;
					height:28px;
					line-height:28px;
					text-align:center;
					font-size:10px;
					padding:0;
					border-radius:100%;
				}

			}

			.form-control{
				border-color: #FFF;
				font-size:12px;
				color:#8E8E89;

				@include placeholdIt{
					color:#8E8E89;
				}

			}

			.input-group{
				
				.input-group-addon{
					display:flex;
					font-family: 'lato-bold';

					.btn{
						font-size:13px;
						border-radius: 0;
						@include button-variant(#FFF, #FFF);
						color:#1F1F1F;

						&:hover{
							border-color: #222;
						}
					}

				}

			}

			.btn{
				white-space:normal;
			}

			.card-preco{
				margin-bottom: 15px;
				display: flex;
				flex-wrap: wrap;
				text-align: center;

				.special,
				.boleto{
					font-family: 'lato-bold';
				}

				.boleto{
					font-size: 18px;
					color:#000;
				}

				.special{
					color:#000;
					font-size:13px;
					
					span{
						font-size: 20px;
					}

				}

				.cond{
					color:#263351;
					font-size:12px;
				}

				@include media-breakpoint-up(sm){
					flex-direction: row;
					align-items: center;

					.boleto{
						border-left:2px solid #d1d0d0;
					}
				}

				& > *{
					min-width: 50%;
					padding: 15px;
				}
			}

			.card-body-adicionar{

				.btn{
					padding:6px 12px;
					font-size:18px;

				}

			}

			.compartilhar{

				.h5{
					font-size:12px;
					color:#1F1F1F;
					margin-bottom:0;
					font-family: 'latoblack';
				}

				a{
					display: inline-block;
					margin-right: 0.5em;
				}
			}
		}

		.avaliar{

			button,
			h2,
			.radio-custom{
			}

			h2 {
				color: #404040;
				font-size: 18px;
				margin-bottom: 10px;
			}

			.subtitle{
				font-size:15px;
				color:#1F1F1F;
				font-family: 'latoblack';
				margin-bottom:15px;
			}

			.form-group{

				.subtitle{
					margin-bottom:10px;
				}

				.form-control{
					resize:none;
					background-color:#eeeeee;
					@include sombra(#EEE);
					
					border:none;
				}

			}

			.radio-custom{
				font-size:15px;
				color:#999999;

			}

		}

		.media-legal .badge,
		.depoimento .badge{
			display:inline-block;
		}

	}

	&.internas-login{

		.row-principal{

			& > *{

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					flex-direction:column;
					justify-content:flex-end;
				}

			}

		}

	}

}