.menu-logado-topo{
	padding-left:0;
	list-style-type:none;
	margin-bottom:0;
	min-width:256px;
	font-size:13px;
	position:absolute;
	top:100%;
	left:15px;
	z-index:600;
	display:none !important;

	&:after{
		content:'';
		display:block;
		position:absolute;
		left:4px;
		bottom:calc(100% - 20px);
		border:9px solid transparent;
		border-bottom-color:$theme;
	}

	&:before{
		content:'';
		display:block;
		opacity:0;
		border-top:20px solid transparent;
	}

	li.menu-header{
		background-color:$theme;
		color:#FFF;
		@extend .lato-bld;
		padding:23px 15px;
		word-break:break-all;
	}

	& > li{
		padding-left:10px;
		padding-right:10px;
		background-color:#FFF;

		&.active > a,
		&:hover  > a{
			color:$theme;
		}

		& + li{
			
			& > a{
				border-bottom:1px solid #EDEDED;
			}

		}

		& > a{
			display:block;
			padding:16px 15px;
		}

	}

	li.menu-footer{
		padding-top:8px;
		padding-bottom:8px;
		text-align:center;

		.btn{
			display:inline-block;
		}

	}

}