.card-promocao{
	text-align:center;

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	.header-card{

		.figure-card{
			position:relative;
			max-width:388px;
			margin-left:auto;
			margin-right:auto;

			&:before{
				content:'';
				display:block;
				width:388px;
				height:210px;
				background-color:transparent;
				position:absolute;
				border:10px solid #dca421;
				z-index:-2;
				top:50%;
				transform:translateY(-50%);
				left:0;
			}

		}

	}

	.body-card{

		.title-carousel{
			font-size:75px;
			font-family: 'latoblack';
			line-height:1;
			margin-bottom:15px;

			@include media-breakpoint-down(md){
				font-size:30px;
			}

		}

		.title-promocao{
			font-size:18px;
			color:#1b1b1b;
			margin-bottom:10px;
			line-height:1;
		}

		.desc{
			margin-bottom:10px;
		}

		.btn-eye{
			background-color:#D1D1D1;
			border-radius:100%;
			width:67px;
			height:67px;
			display:flex;
			align-items:center;
			justify-content:center;
			border:8px solid #E4E4E4;
			font-size:16px;
		}

		.row-valores,
		.row-botoes{

			@include media-breakpoint-down(md){
				justify-content:center;
			}

		}

		.row-valores{

			& > *{
				line-height:1;
				margin-bottom:12px;
				color:#1b1b1b;
			}

			.preco-desconto{
				font-size:14px;
			}

			.preco-real{
				font-family: 'latoblack';
				font-size:25px;
			}

		}

		.row-botoes{

			& > *{
				display:flex;
				flex-wrap:wrap;
				font-family: 'lato-bold';

				& > *{
					display:flex;
					align-items:center;
				}

			}

		}

	}

}