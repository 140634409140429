.topo{

	.bg-menu{
		background-color:rgba(#000,0.5);
		opacity:0;
		transition:opacity 0.6s linear;
		width:100%;
		position:fixed;
		top:0;
		left:0;
	}

	.bar-topo{
		background-color:$theme;
		color:#FFF;

		.container{

			@include media-breakpoint-up(sm){
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				align-items:center;
			}

			& > *{
				padding-top:10px;
				padding-bottom:10px;
			}

		}

	}

	.nav-content{
		padding-top:25px;

		@include media-breakpoint-down(lg){
			position:fixed;
			top:0;
			right:-230px;
			width:230px;
			height:100%;
			background-color:#FFF;
			z-index:1000;
			transition:right 0.6s linear;
			color:#1F1F1F;
			border-left:1px solid $theme;
			overflow:auto;

			.logo-desktop{
				padding:30px;
			}

			.parte-user{
				display:none;
			}

		}

		@include media-breakpoint-up(xl){
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			@include make-container();
			@include make-container-max-widths();
		}

		.navigation-topo{
			flex-grow:1;

			@include media-breakpoint-up(xl){
				margin-left:42px;
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				align-items:center;
			}

			.menu-categorias-side{
				font-size:15px;
				font-family: 'latoblack';

				& > .btn{
					box-shadow:none;
					padding:0;
					display:flex;
					align-items:center;
					justify-content:center;
					text-align:center;

					@include media-breakpoint-up(xl){
						text-align:left;
					}

				}

				.pontos{
					display:inline-block;
					width:1em;
					height:1em;
					font-size:7px;
					border-radius:100%;
					background-color:#282828;
					position:relative;
					margin-right:8px;

					@include beforeAfter{
						content:'';
						display:block;
						width:100%;
						height:100%;
						background-color:inherit;
						position:absolute;
						left:0;
						border-radius:inherit;
					}

					&:before{
						bottom:calc(100% + 2px);
					}

					&:after{
						top:calc(100% + 2px);
					}

				}

				.texto{
					line-height:1.2;
				}

			}

			.parte-user{

				@include media-breakpoint-up(xl){
					display:flex;
					flex-wrap:wrap;
					justify-content:space-between;
					padding-top:10px;
					padding-bottom:10px;
				}

			}

		}

	}

	&.fx{

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			z-index:500;
			background-color:#FFF;
			animation:fadeInDown 0.6s linear;
			box-shadow:0 0 8px rgba(#000,0.5);
			max-width:100%;
			width:100%;
		}

	}

	.bar-categorias{
		background-color:$theme;

		.botao-categorias{

			@include dk(){
				padding-bottom:8px;
				padding-top:14px;
			}

			.btn{
				padding:0;
				border:none;
			}

		}

		@include media-breakpoint-up (lg){
			position:relative;
			display:flex;
			justify-content:space-between;
			align-items:center;

			@include beforeAfter{
				content:'';
				position:absolute;
				top:0;
				width:100vw;
				height:100%;
				background-color:inherit;
				z-index:-2;
			}

			&:before{
				left:100%;
			}

			&:after{
				right:100%;
			}

		}

	}

}

body{
	transition:all 0.6s linear;

	&.sombra{

		.topo{

			.bg-menu{
				opacity:1;
				height:100%;
				z-index:900;
			}

		}

	}

	&.menu-active{

		.topo{

			.nav-content{
				right:0;
			}

		}

	}

	&.menu-categoria-active{

		@include media-breakpoint-up(xl){
			padding-left:200px;
		}

		.topo{

			.menu-collapse{
				left:0;
			}

		}

	}

}
