.form-cadastro{
	@extend .form-tipo-1;

	.h6{
		font-size:17px;
		color:#000;
		font-family: 'latoblack';
	}

	label{
		margin-bottom:0;
	}

	.limitador{

		@extend .pl-lg-4

	}

}