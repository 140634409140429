.header-section{
	font-size:20px;

	& > *{
		margin-bottom:0;
	}

	.title{
		font-size:1em;
		color:#1b1b1b;
		font-family: 'latoblack';

		&.title-circles{

			& > span{
				position:relative;


				@include beforeAfter{
					content:'';
					width:11px;
					height:11px;
					background-color:#1B1B1B;
					border-radius:100%;
					display:inline-block;
					position:absolute;
					top:50%;
					transform:translateY(-50%);
				}

				&:before{
					left:calc(100% + 8px);
				}

				&:after{
					right:calc(100% + 8px);
				}

				@include media-breakpoint-down(md){

					@include beforeAfter{
						position:relative;
						right:unset;
						display:block;
						left:unset;
						margin-left:auto;
						margin-right:auto;
						transform:none;
						top:0;
						left:0;
					}

					&:before{
						margin-bottom:10px;
					}

					&:after{
						margin-top:10px;
					}

				}

			}

		}

	}

}