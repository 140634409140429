.btn{
	font-size:14px;

	&.btn-pill{
		border-radius: 2rem;
	}

	&.pdg-8-12{
		padding:8px 12px;
	}

	&.pdg-18-20{
		padding:18px 20px;
	}

	&.pdg-18-20-2-20{
		padding:24px 20px 18px 20px;
	}

	&.btn-min-width-92{
		min-width:92px;
	}

	&.btn-min-width-120{
		min-width:120px;
	}

	&.btn-min-width-176{
		min-width:176px;
	}

	&.btn-min-width-195{
		min-width:195px;
	}

	&.btn-min-width-240{
		min-width:240px;
	}

	&.btn-radius-30{
		border-radius:30px;
	}

	&.btn-radius-100{
		border-radius:100%;
	}

	&.btn-gray{
		@include button-variant(#EDEDED,#EDEDED);
	}

	&.btn-theme{
		@include button-variant(lighten($theme,0%),lighten($theme,0%));
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten($theme2,15%),lighten($theme2,15%));
			color:#ccc !important;
		}

	}

	&.btn-theme-to-theme-darken{
		@include button-variant(lighten($theme,0%),lighten($theme,0%));
		color:#FFF;

		@include hover-focus{
			@include button-variant(darken($theme,5%),darken($theme,5%));
			color:#FFF;
		}

	}

	&.btn-theme-text-white{
		@include button-variant(lighten($theme,0%),lighten($theme,0%));
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten($theme2,15%),lighten($theme2,15%));
			color:#FFF !important;
		}

	}

	&.btn-theme-darken-text-white{
		@include button-variant(darken($theme,10%),darken($theme,10%));
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten($theme,15%),lighten($theme,15%));
			color:#FFF !important;
		}

	}

	&.btn-gray-mais{
		@include button-variant(#e4e4e4,#e4e4e4);
		color:#898989;

		@include hover-focus{
			@include button-variant(lighten(#e4e4e4,15%),lighten(#e4e4e4,15%));
			color:#000 !important;
		}

	}

	&.btn-finaliza-compra{
		font-weight:bold;
		box-shadow:0 2px #aeb3aa;
		@include button-variant(#6AC017, #6AC017);
		line-height:1;
		padding-left: 50px;
		min-width: 200px;
		text-align: left;
		position: relative;
		color:#FFF;
		font-size:13px;
		border-radius:5px;

		.subtitle{
			font-size:16px;
		}

		&:before,
		&:after{
			content:'';
			display: block;
			position: absolute;
		}

		&:before{
			width:20px;
			height: 10px;
			background: currentcolor;
			left:0.75rem;
			top:calc(50% - 5px);
		}

		&:after{
			border:10px solid transparent;
			border-left-color:#FFF;
			left: calc(0.75rem + 20px);
			top: calc(50% - 10px);
		}

		span{
			display: block;
			padding-left: 0.75rem;
			padding-bottom:0.35rem;
			padding-top:0.35rem;
			white-space:normal;
			font-family: 'lato-bold';

			&:before{
				content:'';
				height:100%;
				border-left: 3px solid rgba(darken(#6AC017,5%),0.2);
				position: absolute;
				top:0;
				left: 50px;
			}
		}
	}

}