.form-tipo-1{

	form{

		.form-group,
		.input-group{
			margin-bottom:15px;

			.form-control{
				min-height:41px;
				font-size:14px;
				@include place(#1f1f1f);
				background-color:#ededed;
				border:none;
				border-radius:0;
				padding-left:26px;
				@include sombra(#ededed);
			}

			textarea{
				padding-top:14px;
				resize:none;
			}

		}

	}

	&:not(.form-com-label){

		form{

			.form-group,
			.input-group{

				label{
					@include sr-only();
				}

			}

		}

	}

	&.form-radius-6{

		form{

			.input-group,
			.form-group{

				.form-control{
					border-radius:6px;
				}

			}

		}

	}

}

.form-max-width-925{
	max-width:925px;
}

.form-max-width-925,
.form-max-width-1020{
	margin-right:auto;
	margin-left:auto;
}

.form-max-width-1020{
	max-width:1020px;
}