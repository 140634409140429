.redes-sociais{

	&.redes-sociais-rodape{
		margin-bottom:15px;

		& > *{
			font-size:14px;
			width:(35 / 14) * 1em;
			height:(35 / 14) * 1em;
			line-height:(35 / 14) * 1em;
			text-align:center;
			color:#747474;
			border-radius:100%;
			border:1px solid currentColor;
			@include margin(0,3);
			font-weight:bold;

			@include hover-focus{
				background-color:$theme;
				color:#FFF;
			}

		}

	}

	&.redes-sociais-internas{

		& > *{
			font-size:16px;
			width:(24 / 16) * 1em;
			height:(24 / 16) * 1em;
			line-height:(24 / 16) * 1em;
			background-color:#000;
			color:#FFF;
			text-align:center;
			border-radius:100%;
			@include margin(0,10);

			@include hover-focus{
				background-color:$theme;
			}

		}

	}

}