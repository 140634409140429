.paginacao{
	display: flex;
	flex-wrap: wrap;

	& > * {
		display: inline-block;
		padding: 5px 10px;
		border: 1px solid #CCC;

		& ~ *{
			margin-left: -1px;
		}

		&:first-child{
			border-radius:4px 0 0 4px;
		}

		&:last-child{
			border-radius:0 4px 4px 0;
		}
	}

	a{
		color: inherit;

		&:hover{
			text-decoration: none;
			color: $primary;
			background: #eee;
		}
	}

	span{
		background: $primary;
		color: color-contrast($primary);
	}
}
